<template>
    <div>
        <v-layout wrap justify-center>
            <v-snackbar v-model="showSnackBar" color="black">
                <v-layout wrap justify-center>
                    <v-flex text-left class="align-self-center">
                        <span style="color: white">
                            {{ msg }}
                        </span>
                    </v-flex>
                    <v-flex text-right>
                        <v-btn small :ripple="false" text @click="showSnackBar = false">
                            <v-icon style="color: white">mdi-close</v-icon>
                        </v-btn>
                    </v-flex>
                </v-layout>
            </v-snackbar>
            <vue-element-loading :active="appLoading" :is-full-screen="true" background-color="#FFFFFF" color="#13736f"
                spinner="spinner" />
            <v-flex xs12 text-center>




                <v-layout v-if="list.length > 0" wrap justify-center>
                    <v-flex pl-8 style="
                  font-family: opensansregular;
                  color: black;
                  font-size: 30px;
                  font-weight: bold;
                " xs11 pa-4 text-left>Wishlist</v-flex>


                    <v-flex xs12>
                        <v-layout wrap justify-center pl-8>
                            <v-flex xs11>
                                <v-layout wrap justify-center>
                                    <v-flex xs8 sm5 lg3 pb-3 v-for="(item, i) in list" :key="i">
                                        <v-card elevation="0" outlined width="270px">
                                            <v-layout wrap justify-center pa-3>
                                                <center>
                                                    <v-flex class="hidden-sm-and-down" pl-8 xs4 style="cursor: pointer;"
                                                        @click="goToDetailedView(item.productId._id)">
                                                        <v-avatar size="90px">
                                                            <v-img :src="mediaURLnew + item.productId.photos[0]"></v-img>
                                                        </v-avatar>
                                                    </v-flex>
                                                </center>
                                                <v-flex class="hidden-md-and-up" pl-7 xs4 style="cursor: pointer;"
                                                    @click="goToDetailedView(item.productId._id)">
                                                    <v-avatar size="90px">
                                                        <v-img :src="mediaURLnew + item.productId.photos[0]"></v-img>
                                                    </v-avatar>
                                                </v-flex>
                                                <v-flex pl-16 v-if="!item.inWishlist" xs5 text-right
                                                    @click="addtoWishlist(item.productId._id)" style="
          
          cursor: pointer;
        ">
                                                    <v-icon color="black" size="200%">mdi-bookmark-outline</v-icon>
                                                </v-flex>
                                                <v-flex pl-16 style="
          
          cursor: pointer;
        " v-else xs5 text-right @click="addtoWishlist(item.productId._id)">
                                                    <v-icon color="#E96125" size="200%">mdi-bookmark</v-icon>
                                                </v-flex>

                                                <v-flex @click="$router.push('/products?id=' + item.productId._id)" pt-3
                                                    text-center xs12
                                                    style="color: #BCB4B4;cursor: pointer; font-size: 12px;"
                                                    class="opensansregular">
                                                    {{ item.productId.categoryId.name }}
                                                </v-flex>

                                                <v-flex pt-1 text-center xs12 class="dmsansregular"
                                                    style="color: black; font-size: 20px;cursor: pointer; font-weight: 500">
                                                    {{ item.productId.name }}
                                                </v-flex>

                                                <v-flex @click="$router.push('/products?id=' + item.productId._id)" pt-1
                                                    text-center xs12 class="dmsansregular"
                                                    style="color: black;cursor: pointer; font-size: 14px; font-weight: 600">
                                                    {{ item.productId.weight }} {{ item.productId.weightType }}
                                                </v-flex>
                                                <v-flex pt-1 text-center xs12 class="dmsansregular"
                                                    style="color: #29807c; font-size: 20px; font-weight: 700">
                                                    Rs {{ item.productId.price }}
                                                </v-flex>
                                                <v-flex v-if="!item.inCart" pt-1 text-center xs12>
                                                    <v-btn v-if="item.productId.stock > 0"
                                                        @click="addtoCart(item.productId._id)" class="no-uppercase"
                                                        width="110px" color="#13736f"><span style="
                                font-family: opensansregular;
                                font-size: 14px;
                                color: #FFFFFF;
                                letter-spacing: 1px;
                                cursor: pointer;
                              ">&nbsp;Add to Cart &nbsp;
                                                        </span>
                                                    </v-btn>
                                                    <v-btn v-else disabled class="no-uppercase" width="110px"
                                                        color="#13736f"><span style="
                              font-family: opensansregular;
                              font-size: 14px;
                              color: red;
                              letter-spacing: 1px;
                              cursor: pointer;
                            ">&nbsp;Out of Stock &nbsp;
                                                        </span>
                                                    </v-btn>

                                                </v-flex>

                                                <v-flex v-else pt-1 text-center xs12>
                                                    <v-btn v-if="item.productId.stock > 0" @click="$router.push('/cart')"
                                                        class="no-uppercase" width="110px" color="#13736f"><span style="
                                font-family: opensansregular;
                                font-size: 14px;
                                color: #FFFFFF;
                                letter-spacing: 1px;
                                cursor: pointer;
                              ">&nbsp;Go to Cart &nbsp;
                                                        </span>
                                                    </v-btn>
                                                    <v-btn v-else disabled class="no-uppercase" width="110px"
                                                        color="#13736f"><span style="
                              font-family: opensansregular;
                              font-size: 14px;
                              color: red;
                              letter-spacing: 1px;
                              cursor: pointer;
                            ">&nbsp;Out of Stock &nbsp;
                                                        </span>
                                                    </v-btn>

                                                </v-flex>
                                            </v-layout>
                                        </v-card>
                                    </v-flex>
                                    <div v-if="cartAddSuccess" class="success-message">
                                        Product added to cart successfully!
                                    </div>
                                </v-layout>
                            </v-flex>
                        </v-layout>

                        <v-layout pt-9 pb-6 wrap justify-center>
                            <v-flex align-self-center>
                                <div>
                                    <v-pagination prev-icon="mdi-menu-left" next-icon="mdi-menu-right" :length="pages"
                                        :total-visible="7" v-model="currentPage2" color="#13736f"></v-pagination>
                                </div>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
                <v-layout wrap v-else justify-center>
                    <v-flex xs10 lg4 class="d-flex align-center justify-center">
                        <v-layout wrap justify-center>
                            <v-flex xs9>
                                <v-img contain src="../../assets/Images/wishlist2.png"></v-img>

                            </v-flex>
                            <v-flex xs4 pt-6 pb-14>
                                <v-btn height="40px" class="no-uppercase" @click="$router.push('/DashboardPage')" dark block
                                    color="#13736f"><span style="
                                              font-family: mainfont;
                                              font-size: 15px;
                                              color: white;
                                              letter-spacing: 1px;
                                              cursor: pointer;
                                            ">Go Home
                                    </span>
                                </v-btn>





                            </v-flex>

                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </div>
</template>
    
    
<script>
import axios from "axios";
export default {
    data() {
        return {
            currentPage2: parseInt(localStorage.getItem("currentPage2")) || 1,
            product: [],
            id: this.$route.query.id,
            initialcount: 1,
            pages: 0,
            preview: null,
            link: null,
            prev: null,
            page: 1,
            showSnackBar: false,
            msg: null,
            limit: 10,
            url: null,
            categoryName: null,
            cartAddSuccess: false,
            appLoading: false,
            name: null,
            description: null,
            duration: null,
            icon: null,
            formData: new FormData(),
            formData2: new FormData(),
            listingOrder: null,
            list: [],
            editdailog: false,
            curid: [],
            searchKeyword: null,
            deletedialog: false,
            editdialog: false,
            noOfDays: null,
            amount: null,
            dialog2: false,
        };
    },
    mounted() {
        this.getList();
        this.currentPage2 = parseInt(localStorage.getItem("currentPage2")) || 1;



    },
    watch: {
        currentPage2(newPage) {
            this.getList();
            localStorage.setItem("currentPage2", newPage);

        },
        categoryId() {
            this.getList();
        },
        status() {
            this.getList();
        },
    },
    methods: {
        incrementQuantity() {
            if (this.product.quantityInCart < this.product.stock) {
                this.product.quantityInCart++;
                this.editCart(this.product.quantityInCart);
            }
            if (this.initialcount) {
                this.initialcount++
            }
        },
        decrementQuantity() {
            if (this.product.quantityInCart > 1) {
                this.product.quantityInCart--;
                this.editCart(this.product.quantityInCart);
            }
            if (this.initialcount > 1) {
                this.initialcount--;
            }
        },
        addtoCart2() {
            this.appLoading = true;
            axios({
                method: "POST",
                url: "/cart/add",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    quantity: this.initialcount,
                    productId: this.product._id,

                },
            })
                .then((response) => {
                    this.pages = Math.ceil(this.totalData / response.data.limit);
                    this.appLoading = false;
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
        editCart(updatedQuantity) {
            axios({
                method: "POST",
                url: "/cart/edit",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    id: this.product._id,
                    quantity: updatedQuantity,
                },
            })
                .then((response) => {
                    if (response.data.status === true) {
                        // Cart edited successfully, you can handle any UI update here
                    } else {
                        console.log("Editing cart failed: " + response.data.msg);
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
        goToDetailedView(id) {
            this.$router.push({
                path: '/products',
                query: {
                    id: id,
                    currentPage2: this.currentPage2
                }
            });
        },
        addtoCart(productId) {
            this.appLoading = true;
            this.cartAddSuccess = false; // Variable to track cart addition success

            axios({
                method: "POST",
                url: "/cart/add",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    productId: productId,
                    quantity: this.quantity,
                },
            })
                .then((response) => {
                    this.appLoading = false;
                    if (response.data === true) {
                        this.cartAddSuccess = true;
                    }
                    location.reload();
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },

        addtoWishlist(productId) {
            axios({
                method: "POST",
                url: "/wishlist/add/remove",
                headers: {
                    token: localStorage.getItem("token"),
                },
                data: {
                    productId: productId,
                    wishlistType: "Product",
                },
            })
                .then((response) => {
                    location.reload();
                    if (response.data.status === true) {
                        // Wishlist updated successfully, handle any UI changes
                    } else {
                        console.log("Adding to wishlist failed: " + response.data.msg);
                    }
                })
                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
        getList() {
            this.appLoading = true;
            axios({
                method: "GET",
                url: "/wishlist/list",
                headers: {
                    token: localStorage.getItem("token"),
                },
                params: {
                    wishlistType: "Product",
                    page: this.currentPage2,
                    limit: 4,
                },
            })
                .then((response) => {
                    this.list = response.data.data;
                    this.totalData = response.data.totalLength;
                    this.pages = Math.ceil(this.totalData / response.data.limit);
                    this.appLoading = false;
                })

                .catch((err) => {
                    this.ServerError = true;
                    console.log(err);
                });
        },
    },
};
</script>